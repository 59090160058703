import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import './ImageGrid.css';

export default function ImageGrid() {
  const [images, setImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [msgId, setMsgId] = useState(null);

  useEffect(() => {
    fetch('/data.json')
      .then((response) => response.json())
      .then((data) => setImages(data.images))
      .catch((error) => console.error('Ошибка загрузки изображений:', error));

    const handleMessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.status === 'success' && data.msg_id) {
        setMsgId(data.msg_id); // Сохраняем msg_id для кнопки
      } else {
        console.error('Ошибка получения msg_id:', data.message);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const openModal = (image) => {
    setCurrentImage(image);
    setIsModalOpen(true);
    setMsgId(null); // Сбрасываем msg_id при открытии нового модального окна
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="image-grid">
        {images.length > 0 ? (
          images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`image-${index}`}
              className="grid-image"
              onClick={() => openModal(image)} // Открытие модального окна по клику
            />
          ))
        ) : (
          <p>Загрузка изображений...</p>
        )}
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} imageUrl={currentImage} msgId={msgId} />
    </>
  );
}
