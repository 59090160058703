import React, { useState, useEffect } from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, imageUrl, msgId }) => {
  const [isSending, setIsSending] = useState(false); // Состояние загрузки

  // Слушаем только при открытии модального окна, ничего не делаем, пока оно не открыто
  useEffect(() => {
    if (isOpen) {
      // Отправляем запрос на сервер только если окно открылось
      const dataToSend = JSON.stringify({ action: 'send_image', url: imageUrl });

      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.sendData(dataToSend); // Отправляем данные боту
        console.log("Данные отправлены в Telegram:", dataToSend);
      } else {
        console.error("Telegram WebApp API недоступен.");
      }
    }
  }, [isOpen, imageUrl]); // Следим за состоянием isOpen и imageUrl

  const handleShareMessage = () => {
    if (!msgId) {
      console.error('Ошибка: msgId отсутствует.');
      return;
    }

    if (window.Telegram && window.Telegram.WebApp) {
      setIsSending(true); // Устанавливаем состояние загрузки
      window.Telegram.WebApp.shareMessage(msgId, (success) => {
        setIsSending(false); // Сбрасываем состояние загрузки

        if (success) {
          console.log('Сообщение успешно отправлено!');
        } else {
          console.error('Ошибка при отправке сообщения.');
        }
      });
    } else {
      console.error('Telegram WebApp API недоступен.');
    }
  };

  if (!isOpen) return null; // Если окно не открыто, ничего не рендерим

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <img src={imageUrl} alt="Preview" className="modal-image" />
        <div className="modal-buttons">
          <button onClick={handleShareMessage} disabled={isSending || !msgId}>
            {isSending ? 'Загрузка...' : (msgId ? 'Отправить' : 'Готовится...')}
          </button>
          <button onClick={onClose}>Закрыть</button>
          <p><strong>msgId:</strong> {msgId ? msgId : "Ожидание ответа..."}</p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
