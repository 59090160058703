// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import ImageGrid from './components/ImageGrid';
import Events from './components/Events';
import {
  Icon28MessageCircleFillGreen,
  Icon28GiftCircleFillYellow,
  Icon28CalendarCircleFillRed,
  Icon28CakeCircleFillRaspberryPink,
} from '@vkontakte/icons';
import eventData from './data/events.json';
import './App.css';

function App() {
  const [value, setValue] = useState(0);
  const [bottomOffset, setBottomOffset] = useState(0);
  const themeParams = window.Telegram.WebApp.themeParams;

  document.documentElement.style.setProperty('--bg-color', themeParams.bg_color || '#ffffff');
  document.documentElement.style.setProperty('--text-color', themeParams.text_color || '#000000');
  document.documentElement.style.setProperty('--hint-color', themeParams.hint_color || '#6c757d');
  document.documentElement.style.setProperty('--secondary-bg-color', themeParams.secondary_bg_color || '#f0f0f0');
  document.documentElement.style.setProperty('--button-color', themeParams.button_color || '#0088cc');
  document.documentElement.style.setProperty('--button-text-color', themeParams.button_text_color || '#ffffff');

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/events" replace />} />
          <Route path="/events" element={<Events data={eventData} />} />
          <Route path="/images" element={<ImageGrid />} />
        </Routes>

        <BottomNavigation
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          showLabels
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'var(--bg-color)',
            zIndex: 1000,
          }}
        >
          <BottomNavigationAction
            component={Link}
            to="/images"
            label="Праздники"
            icon={<Icon28GiftCircleFillYellow />}
          />
          <BottomNavigationAction
            component={Link}
            to="/events"
            label="Пожелания"
            icon={<Icon28MessageCircleFillGreen />}
          />
          <BottomNavigationAction
            component={Link}
            to="/images"
            label={<span className="no-wrap">Дни недели</span>}
            icon={<Icon28CalendarCircleFillRed />}
          />
          <BottomNavigationAction
            component={Link}
            to="/images"
            label={<span className="no-wrap">Дни рождения</span>}
            icon={<Icon28CakeCircleFillRaspberryPink />}
          />
        </BottomNavigation>
      </div>
    </Router>
  );
}

export default App;
