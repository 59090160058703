import React from 'react';
import './GreetingsList.css';

const GreetingsList = ({ data }) => {
  return (
    <div className="greetings-list">
      {data.map((item) => (
        <div key={item.id_cat} className="greeting-item">
          <img
            src={item.img}
            alt={item.name}
            className="greeting-icon"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://via.placeholder.com/40"; // Замена при ошибке загрузки
            }}
          />
          <span className="greeting-text">{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default GreetingsList;
